/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "videoDescription_colors_default": "text-black",
  "videoDescription_size_default": "",
  "videoDescription_variant_default": "",
  "container_variant_default": "",
  "container_size_default": "",
  "container_colors_default": "",
  "videoContainer_nextVideo_variant_default": "hidden absolute top-0 right-0 z-[2] justify-end opacity-0 transition-opacity ease-out duration-500 group-hover/area:opacity-100 group-[.jw-ad-active]/area:flex",
  "videoContainer_nextVideo_colors_default": "text-white",
  "videoContainer_nextVideo_size_default": "p-1 w-full",
  "videoContainer_variant_horizontal": "group/area relative bg-contain bg-no-repeat bg-center aspect-video [&>img]:object-cover",
  "videoContainer_variant_vertical": "group/area relative bg-contain bg-no-repeat bg-center aspect-vertical [&>img]:object-cover",
  "videoContainer_size_default": "",
  "videoContainer_colors_default": "",
  "videoContainer_nextVideo_title_variant_default": "overflow-hidden text-ellipsis whitespace-nowrap",
  "videoContainer_nextVideo_title_size_default": "headline-xs max-w-[80%] mb-0",
  "videoContainer_nextVideo_title_colors_default": "",
  "videoContainer_nextVideo_duration_variant_default": "",
  "videoContainer_nextVideo_duration_size_default": "mb-0 ml-1.25 text-body-xs",
  "videoContainer_nextVideo_duration_colors_default": "",
  "videoContainer_duration_colors_default": "text-white",
  "videoContainer_duration_variant_default": "absolute top-1 right-1 z-[1] flex group-[.jw-ad-active]/area:hidden group-[.jw-playing]/area:hidden [&>i]:flex",
  "videoContainer_duration_size_default": "text-body-xs [&>i]:w-4.5 [&>i]:mr-0.5",
  "exit_variant_default": "",
  "exit_size_default": "",
  "exit_colors_default": "",
  "exit_link_variant_default": "right-0 no-underline top-[13%] hover:opacity-90 active:opacity-90 focus:opacity-90 before:absolute absolute cursor-pointer",
  "exit_link_size_default": "text-body-xs after:ml-2.5 before:inset-[-10px] py-1.5 px-3",
  "exit_link_colors_default": "text-white bg-black/70",
  "exit_label_variant_default": "top-3 left-3 w-full absolute cursor-pointer",
  "exit_label_colors_default": "text-white",
  "exit_label_size_default": "text-body-xs p-3",
  "unmute_variant_default": "group hidden [&.show]:flex md:[&.show]:hidden [&.has-volume]:hidden absolute bottom-1/2 left-3 bg-no-repeat whitespace-nowrap items-center transition-[width] duration-100 ease-linear [&.expanded]:overflow-hidden after:absolute after:-top-1.75 after:-left-1.75 after:animate-pulse-ring after:border-solid after:rounded-full [&.expanded]-after:hidden",
  "unmute_size_default": "rounded-2xl after:border-4 w-7.5 h-7.5 [&.expanded]:w-24 [&.expanded]:pl-7.5 after:w-[150%] after:h-[150%]",
  "unmute_colors_default": "bg-white after:border-white",
  "unmute_label_variant_default": "hidden group-[.expanded]:inline-block",
  "unmute_label_colors_default": "text-black",
  "unmute_label_size_default": "text-body-xs",
  "header_variant_default": "flex justify-between items-start text-head relative z-[1]",
  "header_size_default": "mt-3.5 mb-3",
  "header_colors_default": "",
  "header_title_variant_default": "grow-0 shrink-0 non-italic",
  "header_title_size_default": "text-headline-2xs basis-8/12 md:basis-9/12 md:text-headline-sm pr-1 md:pr-0",
  "header_title_colors_default": "text-black",
  "header_link_variant_default": "flex justify-end relative",
  "header_link_size_default": "w-25 md:w-30 h-6 md:h-7.5",
  "header_link_colors_default": "",
  "variant_default": "",
  "colors_default": "border-primary-700",
  "size_vertical": "h-full pb-4 mb-4 md:mb-7.5 not-prose w-[279px] md:w-[400px] max-w-full lg:max-w-col-lg-8 mx-auto",
  "size_horizontal": "w-full h-full pb-4 mb-4 md:mb-7.5 not-prose lg:max-w-col-lg-8 mx-auto",
  "size_default": "w-full mx-auto"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "JwVideoArticle");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;